export default [
  {
    path: '/coach/planning',
    name: 'CoachPlanning',
    component: () =>
      import(
        /* webpackChunkName: "CoachPlanning" */ '../views/Coach/Planning.vue'
      ),
  },
  {
    path: '/coach/planning/appointment/:id',
    name: 'CoachShowAppointment',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowAppointment" */ '../views/Coach/Appointments/ShowAppointment.vue'
      ),
  },
  {
    path: '/coach/planning/create-appointment',
    name: 'CoachCreateAppointment',
    component: () =>
      import(
        /* webpackChunkName: "CoachCreateAppointment" */ '../views/Coach/Appointments/CreateAppointment.vue'
      ),
  },
  {
    path: '/coach/invoices',
    name: 'CoachInvoices',
    component: () =>
      import(
        /* webpackChunkName: "CoachInvoices" */ '../views/Coach/Invoices.vue'
      ),
  },
  {
    path: '/coach/invoices/add',
    name: 'CoachCreateInvoice',
    component: () =>
      import(
        /* webpackChunkName: "CoachCreateInvoice" */ '../views/Coach/Invoices/CreateInvoice.vue'
      ),
  },
  {
    path: '/coach/invoices/show/:id',
    name: 'CoachShowInvoice',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowInvoice" */ '../views/Coach/Invoices/ShowInvoice.vue'
      ),
  },
  {
    path: '/coach/forms',
    name: 'CoachForms',
    component: () =>
      import(/* webpackChunkName: "CoachForms" */ '../views/Coach/Forms.vue'),
  },
  {
    path: '/coach/forms/create-template',
    name: 'CoachCreateFormTemplate',
    component: () =>
      import(
        /* webpackChunkName: "CoachCreateFormTemplate" */ '../views/Coach/FormTemplates/CreateFormTemplate.vue'
      ),
  },
  {
    path: '/coach/forms/update-template/:id',
    name: 'CoachUpdateFormTemplate',
    component: () =>
      import(
        /* webpackChunkName: "CoachUpdateFormTemplate" */ '../views/Coach/FormTemplates/UpdateFormTemplate.vue'
      ),
  },
  {
    path: '/coach/account',
    name: 'CoachAccount',
    component: () =>
      import(
        /* webpackChunkName: "CoachAccount" */ '../views/Coach/Account.vue'
      ),
  },
  {
    path: '/coach/clients',
    name: 'CoachClients',
    component: () =>
      import(
        /* webpackChunkName: "CoachClients" */ '../views/Coach/Clients.vue'
      ),
  },
  {
    path: '/coach/clients/add',
    name: 'CoachCreateClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachCreateClient" */ '../views/Coach/Clients/CreateClient.vue'
      ),
  },
  {
    path: '/coach/clients/:id',
    name: 'CoachShowClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowClient" */ '../views/Coach/Clients/ShowClient.vue'
      ),
  },
  {
    path: '/coach/clients/:id/personal-details',
    name: 'CoachPersonalDetailsClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachPersonalDetailsClient" */ '../views/Coach/Clients/PersonalDetails.vue'
      ),
  },
  {
    path: '/coach/clients/:id/plan',
    name: 'CoachPlanClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachPlanClient" */ '../views/Coach/Clients/Plan.vue'
      ),
  },
  {
    path: '/coach/clients/:id/plan/:planId',
    name: 'CoachPlanClientShow',
    component: () =>
      import(
        /* webpackChunkName: "CoachPlanClientShow" */ '../views/Coach/Clients/ShowPlan.vue'
      ),
  },
  {
    path: '/coach/clients/:id/plan/:planId/sign',
    name: 'CoachPlanClientSign',
    component: () =>
      import(
        /* webpackChunkName: "CoachPlanClientSign" */ '../views/Coach/Clients/SignPlan.vue'
      ),
  },
  {
    path: '/coach/clients/:id/reports',
    name: 'CoachReportsClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachReportsClient" */ '../views/Coach/Clients/Reports.vue'
      ),
  },
  {
    path: '/coach/clients/:id/forms',
    name: 'CoachFormsClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachFormsClient" */ '../views/Coach/Clients/Forms.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/forms/create',
    name: 'CoachCreateForm',
    component: () =>
      import(
        /* webpackChunkName: "CoachFormsClient" */ '../views/Coach/Sessions/CreateForm.vue'
      ),
  },
  {
    path: '/coach/clients/:id/goals',
    name: 'CoachGoalsClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachGoalsClient" */ '../views/Coach/Clients/Goals.vue'
      ),
  },
  {
    path: '/coach/clients/:id/assignments',
    name: 'CoachAssignmentsClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachAssignmentsClient" */ '../views/Coach/Clients/Assignments.vue'
      ),
  },
  {
    path: '/coach/clients/:id/invoices',
    name: 'CoachInvoicesClient',
    component: () =>
      import(
        /* webpackChunkName: "CoachInvoicesClient" */ '../views/Coach/Clients/Invoices.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/reflections/create',
    name: 'CoachCreateReflection',
    component: () =>
      import(
        /* webpackChunkName: "CoachCreateReflection" */ '../views/Coach/Sessions/CreateReflection.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/assignments/create',
    name: 'CoachCreateAssignment',
    component: () =>
      import(
        /* webpackChunkName: "CoachCreateAssignment" */ '../views/Coach/Sessions/CreateAssignment.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/goals/create',
    name: 'CoachCreateGoal',
    component: () =>
      import(
        /* webpackChunkName: "CoachCreateGoal" */ '../views/Coach/Sessions/CreateGoal.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/sessions/:sessionId/assignments/:assignmentId',
    name: 'CoachShowAssignment',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowAssignment" */ '../views/Coach/Sessions/ShowAssignment.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/sessions/:sessionId/goals/:goalId',
    name: 'CoachShowGoal',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowGoal" */ '../views/Coach/Sessions/ShowGoal.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/sessions/:sessionId/forms/:formId',
    name: 'CoachShowForm',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowForm" */ '../views/Coach/Sessions/ShowForm.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/sessions/:sessionId/reflections/:reflectionId',
    name: 'CoachShowReflection',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowReflection" */ '../views/Coach/Sessions/ShowReflection.vue'
      ),
  },
  {
    path: '/coach/clients/:clientId/sessions/:sessionId',
    name: 'CoachShowSession',
    component: () =>
      import(
        /* webpackChunkName: "CoachShowSession" */ '../views/Coach/Sessions/ShowSession.vue'
      ),
  },
  {
    path: '/coach/notifications',
    name: 'CoachNotifications',
    component: () =>
      import(
        /* webpackChunkName: "CoachNotifications" */ '../views/Coach/Notifications.vue'
      ),
  },
  {
    path: '/coach/chat',
    name: 'CoachChat',
    component: () =>
      import(
        /* webpackChunkName: "CoachChat" */ '../views/Shared/ChatView.vue'
      ),
  },
  {
    path: '/coach/chat/:id',
    name: 'ShowChat',
    component: () =>
      import(
        /* webpackChunkName: "ShowChat" */ '../views/Shared/ShowChatView.vue'
      ),
  },
  {
    path: '/coach/trajectories',
    name: 'TrajectoriesView',
    component: () =>
      import(
        /* webpackChunkName: "TrajectoriesView" */ '../views/Coach/Trajectories/TrajectoriesView.vue'
      ),
  },
  {
    path: '/partner-program',
    name: 'PartnerProgramView',
    component: () =>
      import(
        /* webpackChunkName: "PartnerProgramView" */ '../views/Coach/PartnerProgramView.vue'
      ),
  },
]
